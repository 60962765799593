<template>
  <div class="text-left">
    <h5 class="mb-5 mt-5 text-center" v-if="data">{{ data.name }}</h5>
    <div class="container">
      <div class="m-1">
        <div class="row">
          <div class="col-12 d-flex justify-content-end">
            <a class="btn btn-primary m-1" :href="'https://newsletter.comline.shop/' + data.mailing + '/index.htm'" target="_blank" v-if="data">
              Online-Ansicht
            </a>
            <button
              class="btn btn-primary m-1"
              @click="dublicate(this.$route.params.id)"
            >
              <font-awesome-icon :icon="['fas', 'clone']" /> Dublizieren
            </button>
            <router-link
              class="btn btn-primary m-1"
              :to="`/mailinfo/evaluation/${this.$route.params.id}`"
            >
              Auswertung</router-link
            >
            <!-- <router-link  class="btn btn-primary m-1" :to="`/mailing/singledelivery/${this.$route.params.id}`">  Sonderversand</router-link > -->
          </div>
        </div>
      </div>
      <div class="row" v-show="show === 'Link'">
        <div class="col">
          <div class="custombox p-3 m-1" v-if="data">
            <div class="row text-center">
              <div class="col-12" v-if="data.description">
                <p>{{ data.description }}</p>
              </div>
              <div class="col-4">
                <font-awesome-icon
                  :icon="['fas', 'envelope']"
                  size="2x"
                  class=""
                />
                <p>Vesendete Mails: <br />{{ sentMails }}</p>
              </div>
              <div class="col-4">
                <font-awesome-icon
                  :icon="['fas', 'paper-plane']"
                  size="2x"
                  class=""
                />
                <p>
                  Versand begonnen: <br />{{
                    data.sendMailStart.toDate().toLocaleDateString("de-DE", {
                      year: "2-digit",
                      month: "2-digit",
                      day: "2-digit",
                    })
                  }}
                  {{
                    data.sendMailStart.toDate().toLocaleTimeString("de-DE", {
                      hour: "2-digit",
                      minute: "2-digit",
                    })
                  }}
                  Uhr
                </p>
              </div>
              <div class="col-4">
                <font-awesome-icon
                  :icon="['fas', 'flag-checkered']"
                  size="2x"
                  class=""
                />
                <p>
                  Versand abgeschlossen: <br />{{
                    data.sendMail.toDate().toLocaleDateString("de-DE", {
                      year: "2-digit",
                      month: "2-digit",
                      day: "2-digit",
                    })
                  }}
                  {{
                    data.sendMail.toDate().toLocaleTimeString("de-DE", {
                      hour: "2-digit",
                      minute: "2-digit",
                    })
                  }}
                  Uhr
                </p>
              </div>
              <div class="col-4">
                <font-awesome-icon
                  :icon="['fas', 'stopwatch']"
                  size="2x"
                  class=""
                />
                <p>Sendedauer: <br />{{ getSendingDuration() }}</p>
              </div>
              <div class="col-4">
                <font-awesome-icon :icon="['fas', 'eye']" size="2x" class="" />
                <p>Ansichten bereinigt: <br />{{ views }}</p>
              </div>
              <div class="col-4">
                <font-awesome-icon
                  :icon="['fas', 'file-import']"
                  size="2x"
                  class=""
                />
                <p>
                  Letzter Preisimport<br />
                  {{
                    data?.priceImport &&
                    data.priceImport.date.toDate().toLocaleDateString("de-DE", {
                      year: "2-digit",
                      month: "2-digit",
                      day: "2-digit",
                    })
                  }}
                  {{
                    data?.priceImport &&
                    data.priceImport.date.toDate().toLocaleTimeString("de-DE", {
                      hour: "2-digit",
                      minute: "2-digit",
                    })
                  }}
                  Uhr
                </p>
              </div>
            </div>
            <hr />
            <div class="row">
              <template v-if="data.deluxeFilter">
                <div class="col">
                  <p>
                    Kunden-Stichworte [and]:
                    <span
                      v-for="(word, index) in data?.deluxeFilter
                        ?.customerKeywords?.and"
                      :key="index"
                      ><b>{{ word }}</b
                      ><span
                        v-show="
                          index + 1 !==
                          data?.deluxeFilter?.customerKeywords?.and.length
                        "
                        >,
                      </span>
                    </span>
                  </p>
                  <p>
                    Kunden-Stichworte [or]:
                    <span
                      v-for="(word, index) in data?.deluxeFilter
                        ?.customerKeywords?.or"
                      :key="index"
                      ><b>{{ word }}</b
                      ><span
                        v-show="
                          index + 1 !==
                          data?.deluxeFilter?.customerKeywords?.or.length
                        "
                        >,
                      </span>
                    </span>
                  </p>
                </div>
                <div class="col">
                  <p>
                    Sprache:
                    <b>{{ data?.deluxeFilter?.language || "Keine" }}</b>
                  </p>
                  <p>
                    Personen-Stichworte [or]:
                    <span
                      v-for="(word, index) in data?.deluxeFilter?.personKeywords
                        ?.or"
                      :key="index"
                      ><b>{{ word }}</b
                      ><span
                        v-show="
                          index + 1 !==
                          data?.deluxeFilter?.personKeywords?.or.length
                        "
                        >,
                      </span>
                    </span>
                  </p>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col mb-3">
          <div class="custombox p-3 m-1">
            <div>
              <iframe class="iframe" title="Mailing" :src="iframeSrc"> </iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getFirestore,
  getDoc,
  doc,
  collection,
  getDocs,
  addDoc,
} from "firebase/firestore";
import { mapGetters } from "vuex";

export default {
  name: "Mailinfo",
  components: {
  },
  computed: {
    ...mapGetters({
      settings: "settings",
    }),
  },
  data() {
    return {
      working: true,
      show: "Link",
      data: null,
      sentMails: 0,
      views: 0,
      linkChartData: null,
      links: [],
      iframeSrc: null,
    };
  },
  methods: {
    async loadData() {
      const docRef = await doc(
        getFirestore(),
        "mailing",
        this.$route.params.id
      );
      const snap = await getDoc(docRef);
      const data = snap.data();
      this.data = data;
      this.iframeSrc = this.settings.webserver + data.mailing + "/index.htm";
      return "ok";
    },
    async getSentMails() {
      const ref = await collection(
        getFirestore(),
        "mailing",
        this.$route.params.id,
        "webview"
      );
      getDocs(ref).then((res) => {
        this.sentMails = res.docs.length;
      });
    },
    async getViews() {
      const ref = await collection(
        getFirestore(),
        "mailing",
        this.$route.params.id,
        "views"
      );
      getDocs(ref).then((res) => {
        this.views = res.docs.length;
      });
    },
    async getClicks() {
      const ref = await collection(
        getFirestore(),
        "mailing",
        this.$route.params.id,
        "clicks"
      );
      getDocs(ref).then((res) => {
        this.sentMails = res.docs.length;
      });
    },
    getSendingDuration() {
      let msToTime = (duration) => {
        let milliseconds = parseInt((duration % 1000) / 100),
          seconds = Math.floor((duration / 1000) % 60),
          minutes = Math.floor((duration / (1000 * 60)) % 60),
          hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

        hours = hours < 10 ? "0" + hours : hours;
        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        let hoursReturn = hours > 0 ? hours + " Stunden " : "";
        let minutesReturn = minutes > 0 ? minutes + " Minuten " : "";
        let secondsReturn = seconds > 0 ? seconds + " Sekunden " : "";
        let millisecondsReturn =
          milliseconds > 0 ? milliseconds + " Millisekunden " : "";

        return hoursReturn + minutesReturn + secondsReturn + millisecondsReturn;
      };

      const Date1 = this.data.sendMailStart.toDate();
      const Date2 = this.data.sendMail.toDate();
      return msToTime(Date2 - Date1);
    },
    async dublicate(id) {
      await getDoc(doc(getFirestore(), "mailing", id)).then(async (snap) => {
        const oldData = snap.data();
        let newData = {};
        newData.name = "Kopie - " + oldData.name;
        newData.timestamp = new Date();
        newData.priceImport = false;
        newData.mailing = oldData.mailing;
        newData.sender = oldData.sender;
        newData.deluxeFilter = oldData.deluxeFilter;
        newData.recipientListRequestObject = oldData.recipientListRequestObject;

        await addDoc(collection(getFirestore(), "mailing"), newData).then(
          (a) => {
            this.$router.push(`/mailing/general/${a.id}`);
          }
        );
      });
    },
  },
  async mounted() {
    await this.loadData();
    await this.getSentMails();
    await this.getViews();
    await this.getClicks();
    this.working = false;
  },
};
</script>

<style scoped>
.custombox {
  border: 1px #ced4da solid;
  border-radius: 0.25rem;
}
.iframe {
  width: 100%;
  height: 100%;
  min-height: 900px;
}
</style>
