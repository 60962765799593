<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col text-center">
          <h3 class="mb-5 mt-5" >Settings</h3>
        </div>
      </div>
      <div class="row">
        <div class="col text-left">
          <ul class="nav nav-tabs">
            <li class="nav-item">
              <a :class="`nav-link ${page === 'sender' && 'active'}`" href="#" @click="page = 'sender'">E-Mail-Absender</a>
            </li>
            <li class="nav-item">
              <a :class="`nav-link ${page === 'killswitch' && 'active'}`" href="#" @click="page = 'killswitch'">Killswitch Status</a>
            </li>
          </ul>
          <div class="box">
            <Killswitch v-if="page === 'killswitch'"/>
            <Sender v-if="page === 'sender'"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Killswitch from './settings/Killswitch.vue'
import Sender from './settings/Sender.vue'

export default {
  name: 'Settings',
  components: {
    Killswitch,
    Sender
  },
  data(){
    return{
      page: 'sender'
    }
  }
}
</script>

<style scoped>
.box {
  border: 1px solid #dee2e6;
  border-top: none; 
  border-radius: 0 0 .25rem .25rem;
  padding: 15px;
}
</style>