<template>
  <div class="text-left">
    <h5 class="mb-5 mt-5 text-center">Mailings Versendet</h5>
    <div class="container mb-5">
      <div class="row">
        <div class="col">
        <div class="shadow-none p-3 mb-5 bg-light rounded">
          Hier werden alle Mailings aufgelistet, die Versendet wurden. (Testversand ist keine Versand)
        </div>
        <input type="text" placeholder="Suchbegriff..." class="form-control mb-1" v-model="search" />
          <div class="custombox">
          <table class="table mb-0">
            <thead>
              <tr>
                <th style="background-color: white;" class="sticky-top" scope="col">Name</th>
                <th style="background-color: white;" class="sticky-top" scope="col">Versendet</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(data, index) in showPage" :key="index" @click="goTo(data.id)">
                <td>{{data.data.name}}</td>
                <td>{{data.data.sendMail.toDate().toLocaleDateString('de-DE')}}</td>
              </tr>
            </tbody>
          </table>
           <div class="btn-toolbar m-1 justify-content-between" role="toolbar" aria-label="Toolbar with button groups">
            <div class="btn-group" role="group" aria-label="First group">
              <template v-for="page in maxPages" :key="page">
                <template v-if="maxPages > 25 && page <= 10">
                  <button type="button" :class="`btn btn-sm btn-outline-secondary ${usePage == page && 'active'}`" @click="usePage = page">{{page}}</button>
                </template>

                <template v-if="maxPages > 25 && page == 11 && usePage != 11 && page != (usePage - 1) && page != (usePage + 1)">
                  <button type="button" :class="`btn btn-sm btn-outline-secondary`" disabled @click="usePage = page">...</button>
                </template>

                <template v-if="maxPages > 25 && page > 10 && page < (maxPages - 9) && page >= (usePage - 1) && page <= (usePage + 1)">
                  <button type="button" :class="`btn btn-sm btn-outline-secondary ${usePage == page && 'active'}`" @click="usePage = page">{{page}}</button>
                </template>

                <template v-else-if="maxPages > 25 && page == Math.round(maxPages/2) && usePage < 9 || maxPages > 25 && page == Math.round(maxPages/2) && usePage > (maxPages - 9)">
                  <button type="button" :class="`btn btn-sm btn-outline-secondary`" @click="usePage = page">{{page}}</button>
                </template>

                <template v-if="maxPages > 25 && page == (maxPages - 10) && usePage != (maxPages - 10) && page != (usePage - 1) && page != (usePage + 1)">
                  <button type="button" :class="`btn btn-sm btn-outline-secondary`" disabled @click="usePage = page">...</button>
                </template>

                <template v-if="maxPages > 25 && page > (maxPages - 10)">
                  <button type="button" :class="`btn btn-sm btn-outline-secondary ${usePage == page && 'active'}`" @click="usePage = page">{{page}}</button>
                </template>

                <template v-if="maxPages < 25">
                  <button type="button" :class="`btn btn-sm btn-outline-secondary ${usePage == page && 'active'}`" @click="usePage = page">{{page}}</button>
                </template>
              </template>
            </div>
            <div role="group">
              <select class="form-select" style="width: 85px" v-model="maxPerPage" >
                <option selected :value="15">15</option>
                <option :value="30">30</option>
                <option :value="60">60</option>
                <option :value="100">100</option>
                <option :value="200">200</option>
              </select>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getFirestore, getDocs, query, collection, where, orderBy} from "firebase/firestore"; 

export default {
  name: 'Evaluation',
  components:{
  }, 
  computed:{
    tableDataFiltered(){
      return this.list.filter(data => {
        return data.data.name.toLowerCase().includes(this.search.toLowerCase())
      })
    },
    maxPages(){
      return Math.ceil(this.tableDataFiltered.length / this.maxPerPage)
    },
    showPage(){
      const start = this.usePage * this.maxPerPage - this.maxPerPage
      const end = Number(start) + Number(this.maxPerPage)
      return this.tableDataFiltered.length > this.maxPerPage ? this.tableDataFiltered.slice(start, end) : this.tableDataFiltered
    }
  },
  data(){
    return{
      list: [],
      search: '',
      usePage: 1,
      maxPerPage: 100,
    }
  },
  methods:{
    async loadData(){
      const MailingRef = query(collection(getFirestore(), "mailing"), where("sendMail", "!=", ""), orderBy('sendMail', "desc"));
      const querySnapshot = await getDocs(MailingRef);
      querySnapshot.forEach((doc) => {
        this.list.push({ id: doc.id, data: doc.data()})
      })
      return 'ok'
    },
    goTo(id){
      this.$router.push({ name: 'MailingInfo', params: { id: id } })
    }
  },
  mounted(){
    this.loadData()
  }
}
</script>

<style scoped>
  .custombox {
    border: 1px #ced4da solid;
    border-radius: .25rem;
  }
  tr {
    cursor: pointer;
  }
</style>