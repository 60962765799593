<template>
  <Loading v-if="working" />
  <template v-else>

    <div class="mb-3">              
      <label for="language" class="form-label">Sprache</label>
      <select class="form-select" id="language" @change="setLanguage($event)">
        <option value="" :selected="value=='' ? true : false">Keine</option>
        <option v-for="(each, index) in options" :key="index" :selected="value==each.value ? true : false" :value="each.value">{{each.name}}</option>
      </select>
    </div>

  </template>
</template>

<script>

import Loading from "../Loading.vue"

export default {
  name: 'selectLanguage',
  components:{
    Loading
  },
  props: {
    preSelected: String
  },
  watch: {
    preSelected: {
      handler: function(data) {
        console.log(data)
        this.value = data
      },
    deep: true
    },
    value: {
      handler: function(data) {
        this.$emit("selectChanged", data)
      },
    deep: true
    }
  },
  emits: ['selectChanged'],
  data(){
    return{
      options: [
        {name: "Deutsch", value: "de"},
        {name: "Englisch", value: "en"},
        {name: "Französisch", value: "fr"},
        {name: "Spanisch", value: "es"}
      ],
      working: true,
      value: null,
    }
  },
  methods:{
    setLanguage(event) {
      this.value = event.target.value
    }
  },
  mounted(){
    this.value = this.preSelected || ""
    this.working = false
  },
}
</script>

<style scoped>
  #card {
    cursor: pointer;
  }
  .scrollBox {
    border: 1px #ced4da solid;
    border-radius: .25rem;
    max-height: 300px; 
    overflow-y: scroll;
  }
</style>
