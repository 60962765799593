<template>
  <div class="shadow-none p-3 mb-5 bg-light rounded">
    Hier können Absender für den Versand vordefiniert werden.
  </div>

  <Loading v-if="loading" />

  <table class="table mb-0" v-else>
    <thead>
      <tr>
        <th style="background-color: white;" class="sticky-top" scope="col">Name</th>
        <th style="background-color: white;" class="sticky-top" scope="col">E-Mail</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(data, index) in list" :key="index">
        <td>{{data.data.name}}</td>
        <td>{{data.data.email}}</td>
        <td>
          <DropDown class="d-flex justify-content-end" :id="data.id" @openModal="openModal" @delete="deleteModal"/>
        </td>
      </tr>
    </tbody>
  </table>
  <button class="btn btn-primary mt-2" @click="openModal()">E-Mail-Absender hinzufügen</button>

<!-- Modal -->
  <div class="modal" tabindex="-1" ref="Modal">
    <div class="modal-dialog">
      <form class="row g-3" @submit.prevent="save">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>

          <div class="modal-body" v-if="newData">
            <div class="mb-3">
              <label for="name" class="form-label">Name</label><br>
              <input type="text" class="form-control" id="name" placeholder="name" v-model="newData.name">
            </div>

            <div class="mb-3">
              <label for="email" class="form-label">Absender E-Mail</label><br>
              <input type="mail" class="form-control" id="email" placeholder="E-Mail" v-model="newData.email">
            </div>
          </div>
          
          <div class="modal-footer d-flex justify-content-between">
            <button type="button" class="btn btn-secondary m-0" data-bs-dismiss="modal">Abbrechen</button>
            <SaveButton :saving="saving" class="m-0" text="Speichern" :done="done"/>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="modal" tabindex="-1" ref="modalDelete">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" v-if="toDelete">Absender löschen</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body" v-if="toDelete">
          <p>Soll der Verteiler Absender wirklich gelöscht werden?</p>
        </div>
        <div class="modal-footer d-flex justify-content-between">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Abbrechen</button>
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal" style="color: white;" @click="deleteSender()">Löschen</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { getFirestore, updateDoc, doc, addDoc, collection, getDocs, deleteDoc } from "firebase/firestore"; 
import SaveButton from "../smallComponents/SaveButton.vue"
import Loading from "../Loading.vue"
import { Modal } from "@comlinedev/bootstrap5"
import DropDown from "./DropDown.vue"

export default {
  name: 'Absender',
  data(){
    return {
      editId: null,
      newData: {
        email: 'comline@comline-shop.de'
      },
      list: [],
      saving: false,
      loading: true,
      done: false,
      Modal: null,
      toDelete: null,
      modalDelete: null
    }
  },
  components:{
    SaveButton,
    Loading,
    DropDown
  },
  methods: {
    async save(){
      this.saving = true
      // console.log(this.editId)
      if(this.editId){
        await updateDoc(doc(getFirestore(), "sender", this.editId), this.newData);
      } else {
        await addDoc(collection(getFirestore(), "sender"), this.newData);
      }
      await this.loadData()
      this.saving = false
      this.done = true
      setTimeout(()=>{this.done = false; this.Modal.hide()}, 1500);
    },
    openModal(id){
      const list = {}
      this.list.forEach(each=>{list[each.id] = each.data})
      this.editId = id ? id : null
      if (id){this.newData = {name: list[id].name, email: list[id].email}}
      this.Modal.show()
    },
    deleteModal(id){
      this.toDelete = id
      this.modalDelete.show()
    },
    async deleteSender(){
      await deleteDoc(doc(getFirestore(), "sender", this.toDelete))
      await this.loadData()
    },
    async loadData(){
      this.list = []
      this.loading = true
      const SendersRef = collection(getFirestore(), "sender");
      const querySnapshot = await getDocs(SendersRef);
      querySnapshot.forEach((doc) => {
        this.list.push({id:doc.id, data: doc.data()})
      })
      this.loading = false
    }
  },
  async mounted(){
    await this.loadData()
    this.Modal = new Modal(this.$refs.Modal)
    this.modalDelete = new Modal(this.$refs.modalDelete)
  }
}
</script>

<style scoped>

</style>