<template>
  <div class="card">
    <div class="card-body">
      <h5 class="card-title">Abonenten</h5>
      <p>
        <b>Nicht bestätigte Abonenten: </b> {{ notUsers }}
        <Info
          class="ms-2"
          content="Abonenten die sich angemeldet haben aber ihre E-Mail-Adresse nicht bestätigen haben."
          ><font-awesome-icon :icon="['fas', 'question-circle']"
        /></Info>
      </p>
      <p>
        <b>Abmeldungen Gesamt: </b> {{ unsubscribe }}
        <Info class="ms-2" content="Alle Abonenten die sich abgemeldet haben."
          ><font-awesome-icon :icon="['fas', 'question-circle']"
        /></Info>
      </p>
      <!-- unsubscribe -->
      <p>
        <b>Abmeldungen diesen Monat: </b> {{ unsubscribersThisMonth }}
        <Info
          class="ms-2"
          content="Abonenten die sich diesen Monat abgemeldet haben."
          ><font-awesome-icon :icon="['fas', 'question-circle']"
        /></Info>
      </p>
      <!-- unsubscribe -->
      <p>
        <b>Abmeldungen letzten Monat: </b> {{ unsubscribersLastMonth }}
        <Info
          class="ms-2"
          content="Abonenten die sich diesen Monat abgemeldet haben."
          ><font-awesome-icon :icon="['fas', 'question-circle']"
        /></Info>
      </p>
      <!-- unsubscribe -->
      <p>
        <b>Abmeldungen heute: </b> {{ unsubscribersToday }}
        <Info
          class="ms-2"
          content="Abonenten die sich heute abgemeldet haben, oder wurden."
          ><font-awesome-icon :icon="['fas', 'question-circle']"
        /></Info>
      </p>
      <!-- unsubscribe -->
      <p>
        <b>Automatische Abmeldungen Gesamt: </b> {{ autoUnsubscribe }}
        <Info
          class="ms-2"
          content="Alle Abonenten die abgemeldet wurden, weil wir von ihnen als Spam markiert wurden."
          ><font-awesome-icon :icon="['fas', 'question-circle']"
        /></Info>
      </p>
      <!-- unsubscribe -->
      <p>
        <b>Automatische Abmeldungen diesen Monat: </b>
        {{ autoUnsubscribersThisMonth }}
        <Info
          class="ms-2"
          content="Abonenten die diesen Monat abgemeldet wurden, weil wir von ihnen als Spam markiert wurden."
          ><font-awesome-icon :icon="['fas', 'question-circle']"
        /></Info>
      </p>
      <!-- unsubscribe -->
      <p>
        <b>Automatische Abmeldungen heute: </b> {{ autoUnsubscribersToday }}
        <Info
          class="ms-2"
          content="Abonenten die heute abgemeldet wurden, weil wir von ihnen als Spam markiert wurden."
          ><font-awesome-icon :icon="['fas', 'question-circle']"
        /></Info>
      </p>
      <!-- unsubscribe -->
      <p>
        <b>Anmeldungen diesen Monat: </b> {{ subscribersThisMonth }}
        <Info
          class="ms-2"
          content="Abonenten die sich diesen Monat angemeldet haben."
          ><font-awesome-icon :icon="['fas', 'question-circle']"
        /></Info>
      </p>
      <!-- unsubscribe -->
      <p>
        <b>Anmeldungen letzten Monat: </b> {{ subscribersLastMonth }}
        <Info
          class="ms-2"
          content="Abonenten die sich letzten Monat angemeldet haben."
          ><font-awesome-icon :icon="['fas', 'question-circle']"
        /></Info>
      </p>
      <!-- unsubscribe -->
      <p>
        <b>Anmeldungen dieses Jahr: </b> {{ subscribersThisYear }}
        <Info
          class="ms-2"
          content="Abonenten die sich dieses Jahr angemeldet haben."
          ><font-awesome-icon :icon="['fas', 'question-circle']"
        /></Info>
      </p>
      <!-- unsubscribe -->
      <p>
        <b>Temporär deaktivierte Abonenten: </b> {{ subscribersUnreachable }}
        <Info
          class="ms-2"
          content="Abonenten die wegen Softbounces deaktiviert wurden."
          ><font-awesome-icon :icon="['fas', 'question-circle']"
        /></Info>
      </p>
      <!-- unsubscribe -->
      <!-- <p><b>Weitere Daten: </b> n/a</p>
      <p><b>Andere Daten: </b> n/a</p> -->
    </div>
  </div>
</template>

<script>
import {
  collection,
  getFirestore,
  getDocs,
  query,
  where,
  and,
} from "firebase/firestore";
import moment from "moment-timezone";
import Info from "../smallComponents/Info.vue";


export default {
  name: "UsersShort",
  components: {
    Info,
  },
  data() {
    return {
      users: 0,
      notUsers: 0,
      unsubscribe: 0,
      unsubscribersThisMonth: 0,
      unsubscribersLastMonth: 0,
      unsubscribersToday: 0,
      autoUnsubscribe: 0,
      autoUnsubscribersThisMonth: 0,
      autoUnsubscribersToday: 0,
      subscribersThisMonth: 0,
      subscribersLastMonth: 0,
      subscribersThisYear: 0,
      subscribersUnreachable: 0,
    };
  },
  methods: {
    async countNotUsers() {
      const subscribersPendingRef = await collection(
        getFirestore(),
        "subscribersPending"
      );
      getDocs(subscribersPendingRef).then((res) => {
        this.notUsers = res.docs.length;
      });
    },
    async getSingups() {
      const subscribersRef = await collection(getFirestore(), "subscribers");
      const q = await query(
        subscribersRef,
        where("confirmationDate", ">", moment().startOf("month").toDate())
      );
      getDocs(q).then((res) => {
        this.subscribersThisMonth = res.docs.length;
      });
    },
    async getSignupsLastMonth() {
      const subscribersRef = await collection(getFirestore(), "subscribers");
      const q = await query(
        subscribersRef,
        and(
          where(
            "confirmationDate",
            ">=",
            moment().subtract(1, "month").startOf("month").toDate()
          ),
          where(
            "confirmationDate",
            "<=",
            moment().subtract(1, "month").endOf("month").toDate()
          )
        )
      );
      getDocs(q).then((res) => {
        this.subscribersLastMonth = res.docs.length;
      });
    },
    async getSingupsThisYear() {
      const subscribersRef = await collection(getFirestore(), "subscribers");
      const q = await query(
        subscribersRef,
        where("confirmationDate", ">", moment().startOf("year").toDate())
      );
      getDocs(q).then((res) => {
        this.subscribersThisYear = res.docs.length;
      });
    },
    async countUnsubscribed() {
      const subscribersRef = await collection(getFirestore(), "unsubscribed");
      const q = await query(subscribersRef, where("reason", "==", "self"));
      getDocs(q).then((res) => {
        this.unsubscribe = res.docs.length;
      });
    },
    async getSingouts() {
      const subscribersRef = await collection(getFirestore(), "unsubscribed");
      const q = await query(
        subscribersRef,
        where("timestamp", ">", moment().startOf("month").toDate()),
        where("reason", "==", "self")
      );
      getDocs(q).then((res) => {
        this.unsubscribersThisMonth = res.docs.length;
      });
    },
    async getSingoutsToday() {
      const subscribersRef = await collection(getFirestore(), "unsubscribed");
      const q = await query(
        subscribersRef,
        where("timestamp", ">", moment().startOf("day").toDate()),
        where("reason", "==", "self")
      );
      getDocs(q).then((res) => {
        this.unsubscribersToday = res.docs.length;
      });
    },
    async getSignoutsLastMonth() {
      const subscribersRef = await collection(getFirestore(), "unsubscribed");
      const q = await query(
        subscribersRef,
        and(
          where('timestamp', '>=', moment().subtract(1, "month").startOf("month").toDate()),
          where('timestamp', "<=", moment().subtract(1, "month").endOf("month").toDate())
        )
      );
      getDocs(q).then((res) => {
        this.unsubscribersLastMonth = res.docs.length;
      });
    },
    async countAutoUnsubscribed() {
      const subscribersRef = await collection(getFirestore(), "unsubscribed");
      const q = await query(subscribersRef, where("reason", "==", "auto"));
      getDocs(q).then((res) => {
        this.autoUnsubscribe = res.docs.length;
      });
    },
    async getAutoSingouts() {
      const subscribersRef = await collection(getFirestore(), "unsubscribed");
      const q = await query(
        subscribersRef,
        where("timestamp", ">", moment().startOf("month").toDate()),
        where("reason", "==", "auto")
      );
      getDocs(q).then((res) => {
        this.autoUnsubscribersThisMonth = res.docs.length;
      });
    },
    async getAutoSingoutsToday() {
      const subscribersRef = await collection(getFirestore(), "unsubscribed");
      const q = await query(
        subscribersRef,
        where("timestamp", ">", moment().startOf("day").toDate()),
        where("reason", "==", "auto")
      );
      getDocs(q).then((res) => {
        this.autoUnsubscribersToday = res.docs.length;
      });
    },
    async getSubscribersUnreachable() {
      const subscribersUnreachableRef = await collection(
        getFirestore(),
        "subscribersUnreachable"
      );
      getDocs(subscribersUnreachableRef).then((res) => {
        this.subscribersUnreachable = res.docs.length;
      });
    },
  },
  async mounted() {
    this.countNotUsers();
    this.getSingups();
    this.getSubscribersUnreachable();
    this.getSingouts();
    this.getSingoutsToday();
    this.countUnsubscribed();
    this.getAutoSingouts();
    this.getAutoSingoutsToday();
    this.countAutoUnsubscribed();
    this.getSingupsThisYear();
    this.getSignupsLastMonth();
    this.getSignoutsLastMonth();
  },
};
</script>

<style scoped>
small {
  font-size: 10px;
  line-height: 11px;
}
p {
  text-align: left;
}
</style>
